import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Collect from '../pages/Collect';
import Deliveries from '../pages/Deliveries';
import NeighborhoodDeliveries from '../pages/Deliveries/NeighborhoodDeliveries';
import Login from '../pages/Login';
import Manifests from '../pages/Manifests';
import Transfer from '../pages/Transfer';
import Wallet from '../pages/Wallet';
import { PrivateRoute } from './privateRoutes';

const AppRoutes = () => {
	const { user, isEmployee, canCollect } = useAuth();

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/romaneios"
					element={
						<PrivateRoute>
							<Manifests />
						</PrivateRoute>
					}
				/>
				<Route
					path="/entregas"
					element={
						<PrivateRoute>
							<Deliveries />
						</PrivateRoute>
					}
				/>
				<Route
					path="/entregas/bairro"
					element={
						<PrivateRoute>
							<NeighborhoodDeliveries />
						</PrivateRoute>
					}
				/>
				{canCollect && (
					<Route
						path="/coletas"
						element={
							<PrivateRoute>
								<Collect />
							</PrivateRoute>
						}
					/>
				)}
				{isEmployee && (
					<Route
						path="/carteira"
						element={
							<PrivateRoute>
								<Wallet />
							</PrivateRoute>
						}
					/>
				)}
				(
				<Route
					path="/transferencias"
					element={
						<PrivateRoute>
							<Transfer />
						</PrivateRoute>
					}
				/>
				)
				<Route
					path="/login"
					element={user ? <Navigate to="/entregas" /> : <Login />}
				/>
				<Route path="/*" element={<Navigate to="/entregas" />} />
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
