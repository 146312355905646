import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
} from '@mui/material';
import searchTerms from '../../utils/searchTerms.json';

const SearchTermSelect = (props: SelectProps) => (
	<FormControl fullWidth>
		<InputLabel id="operation-select-label">Termo de busca</InputLabel>
		<Select
			labelId="operation-select-label"
			id="operation-select"
			label="Termo de busca"
			variant="outlined"
			defaultValue={searchTerms[0].code}
			{...props}
		>
			{searchTerms.map((term) => (
				<MenuItem key={term.code} value={term.code}>
					{term.label}
				</MenuItem>
			))}
		</Select>
	</FormControl>
);

export default SearchTermSelect;
