import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Stack,
	Typography,
} from '@mui/material';
import { IPackage } from '../../../../DTOs';
import ReverseBadge from '../../../../components/ReverseBadge';

const cardStyles = {
	width: '100%',
	maxWidth: '400px',
	background: '#fafafa',
	boxShadow:
		'0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
	borderRadius: '5px',
};

interface IPackageCardProps {
	showDetails: (data: IPackage) => void;
	data: IPackage;
}

const PackageCard = ({ showDetails, data }: IPackageCardProps) => (
	<Box sx={cardStyles}>
		<Stack
			width="100%"
			direction="column"
			justifyContent="flex-start"
			alignContent="stretch"
			padding="16px"
			gap={1}
		>
			<Box>
				{data.isBlockDelivery && !data.isCommercialAgreement && (
					<Alert
						severity="warning"
						sx={{
							width: '100%',
							marginBottom: '0.25rem',
							color: 'black',
							fontWeight: 'bold',
						}}
					>
						<AlertTitle>Atenção</AlertTitle>
						PRAZO EXPIRADO, FINALIZAR!
					</Alert>
				)}
			</Box>

			<Box>
				{data.isCommercialAgreement && (
					<Alert
						severity="error"
						sx={{
							width: '100%',
							marginBottom: '0.25rem',
							color: 'black',
							fontWeight: 'bold',
						}}
					>
						<AlertTitle>Atenção</AlertTitle>
						PACOTE BLOQUEADO PARA ENTREGA ! RETORNE AO CD
					</Alert>
				)}
			</Box>

			<Box>
				<Typography>Pacote</Typography>
				<ReverseBadge isReverse={data.isReverse}>
					<Typography>{data.alphaCode}</Typography>
				</ReverseBadge>
			</Box>
			<Box>
				<Typography>Endereço</Typography>
				<Typography>{data.location}</Typography>
			</Box>
			{data.complement && (
				<Box>
					<Typography>Complemento</Typography>
					<Typography>{data.complement}</Typography>
				</Box>
			)}
			<Box>
				<Typography>CEP</Typography>
				<Typography>{data.zipcode}</Typography>
			</Box>
			<Box>
				<Typography>Destinatário</Typography>
				<Typography>{data.recipient}</Typography>
			</Box>
			<Box>
				<Typography>Contato</Typography>
				<Typography>{data.phone}</Typography>
			</Box>

			<Button
				disabled={data.isCommercialAgreement}
				fullWidth
				variant="contained"
				onClick={() => showDetails(data)}
			>
				Detalhes
			</Button>
		</Stack>
	</Box>
);

export default PackageCard;
