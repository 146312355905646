import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { IDeliveryActionsRequest } from '../DTOs';
import type { RootState } from './index';

export interface IRequestQueueItem {
	id: string;
	body: IDeliveryActionsRequest;
	url: string;
	date: string;
	userId: string;
}

export interface IFailedRequestQueueItem extends IRequestQueueItem {
	retries: number;
}

interface IRequestQueueSlice {
	pending: IRequestQueueItem[];
	failed: IFailedRequestQueueItem[];
}

interface IEnqueueItem {
	url: string;
	body: IDeliveryActionsRequest;
	userId: string;
}

const initialState = {
	pending: [],
	failed: [],
} as IRequestQueueSlice;

export const requestQueueSlice = createSlice({
	name: 'requestQueue',
	initialState,
	reducers: {
		enqueuePendingRequest: (state, action: { payload: IEnqueueItem }) => {
			const { url, body, userId } = action.payload;

			const createdAt = new Date().toISOString();

			const bodyIncludingCreateAt = body.packages.map((item) => ({
				...item,
				data: {
					...item.data,
					createdAt,
				},
			}));

			state.pending.push({
				id: v4(),
				userId,
				url,
				body: { packages: bodyIncludingCreateAt, searchBy: body.searchBy },
				date: createdAt,
			});
		},
		dequeuePendingRequest: (state, action: { payload: { id: string } }) => {
			const queueItem = state.pending.find(
				(item) => item.id === action.payload.id
			);

			if (!queueItem) return;

			const index = state.pending.indexOf(queueItem);

			state.pending.splice(index, 1);
		},
		enqueueFailedRequest: (state, action: { payload: IRequestQueueItem }) => {
			state.failed.push({
				...action.payload,
				retries: 0,
			});
		},
		dequeueFailedRequest: (state, action: { payload: { id: string } }) => {
			const queueItem = state.failed.find(
				(item) => item.id === action.payload.id
			);

			if (!queueItem) return;

			const index = state.failed.indexOf(queueItem);

			state.failed.splice(index, 1);
		},
		retryFailedRequest: (
			state,
			action: { payload: IFailedRequestQueueItem }
		) => {
			const queueItem = state.failed.find(
				(item) => item.id === action.payload.id
			);

			if (!queueItem) return;

			const index = state.failed.indexOf(queueItem);

			if (queueItem.retries >= 3) {
				state.failed.splice(index, 1);
			} else {
				state.failed.splice(index, 1, {
					...queueItem,
					retries: queueItem.retries + 1,
				});
			}
		},
	},
});

export const {
	enqueueFailedRequest,
	dequeueFailedRequest,
	dequeuePendingRequest,
	enqueuePendingRequest,
	retryFailedRequest,
} = requestQueueSlice.actions;

export const getPendingRequests = () => (state: RootState) =>
	Array.from(state.requestQueue.pending).sort((a, b) => {
		if (new Date(a.date) > new Date(b.date)) return 1;
		if (new Date(b.date) > new Date(a.date)) return -1;

		return 0;
	});

export const getFailedRequests = () => (state: RootState) =>
	Array.from(state.requestQueue.failed).sort((a, b) => {
		if (new Date(a.date) > new Date(b.date)) return 1;
		if (new Date(b.date) > new Date(a.date)) return -1;

		return 0;
	});

export default requestQueueSlice.reducer;
