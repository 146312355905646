import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { IDeliveryActionsRequest } from '../DTOs';
import type { RootState } from './index';

export interface IHistoryItem {
	id: string;
	type: 'collect' | 'delivery' | 'ocurrence' | 'error';
	createAt: string;
	data: IDeliveryActionsRequest;
}

const initialState = [] as IHistoryItem[];

export const historySlice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		addHistoryItem: (
			state,
			action: { payload: Omit<IHistoryItem, 'id' | 'createAt'> }
		) => {
			state.push({
				id: v4(),
				createAt: new Date().toISOString(),
				...action.payload,
			});
		},
		clearHistory: (state) => {
			state.splice(0, state.length);
		},
	},
});

export const { addHistoryItem, clearHistory } = historySlice.actions;

export const getHistory = () => (state: RootState) => state.history;

export const getHistoryByType =
	(type: 'delivery' | 'collect' | 'error') => (state: RootState) =>
		state.history.filter((item) => item.type === type);

export const getHistoryItemById = (id: string) => (state: RootState) =>
	state.history.filter((item) => item.id === id);

export default historySlice.reducer;
